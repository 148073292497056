import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchOrganisationList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/organisation/list`, payload);
        return response?.data;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchFactoryList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/factory/list`, payload);
        return response?.data;

    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchUnitsList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/unit/list`, payload);
        return response?.data;

    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchPowerPlantsListNearToUnit = async (id, payload) => {
    try {
        const response = await axios.post(`${API_URL}/unit/powerPlants/${id}`, payload);
        return response?.data;

    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchUnitById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/unit/${id}`);
        // console.log(response,'reg-list');
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const updateUnitById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/unit/${id}`, payload);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchMetersList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/meter/list`, payload);
        return response?.data;

    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const fetchOCRList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/ocr/list`, payload);
        return response?.data;

    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchStateList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/state/list`, payload);
        return response?.data;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const fetchDiscomList = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/disComCode/list`, payload);
        return response?.data;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const cleanUpData = async (payload) => {
    
    try {
        const response = await axios.delete(`${API_URL}/cleanUp`, { data: payload });
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const deleteOCRData = async (payload) => {
    
    try {
        const response = await axios.delete(`${API_URL}/ocr/ocrid/${payload.ocrId}`);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const getStateByStateCode = async (code) => {
    try {
        const response = await axios.get(`${API_URL}/state/${code}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error getting user :', error);
        return false;
    }
}

export const getAllStates = async () => {
    try {
        const response = await axios.post(`${API_URL}/state/list`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error getting user :', error);
        return false;
    }
}

export const getStateById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/state/by-id/${id}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response?.data;
    } catch (error) {
        console.error('Error getting user :', error);
        return false;
    }
}


export const addNewPolicy = async (id, policy) => {
    try {
        const response = await axios.put(`${API_URL}/state/${id}`, policy);
        return response.data;
    } catch (error) {
        console.error('Error adding new policy:', error);
        throw error;
    }
};

export const addConstantToPowerPlant = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/powerPlant/addconstant/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error('Error adding new policy:', error);
        throw error;
    }
};



export const fetchOrganisationById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/organisation/${id}`);
        return response?.data;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}


export const updateOrganisationById = async (id, payload) => {
    try {
        const response = await axios.put(`${API_URL}/organisation/${id}`, payload);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const startRecommendationProcess = async (payload) => {
    try {
        const response = await axios.post(`${API_URL}/recommendation/startRecommendation`, payload);
        return response;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}

export const getOrgTreeViewList = async (id,payload) =>{
    try {
        const response = await axios.get(`${API_URL}/organisation/tree/${id}`);
        return response?.data;
    } catch (error) {
        console.error('Error :', error);
        return error?.response?.data;
    }
}