import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/sidebar/Sidebar";
import {
  addNewPolicy,
  getStateById,
  getStateByStateCode,
} from "../../../services/apis/listings";
import { editStateId } from "../../../services/apis/content";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../../components/backButton/BackButton";
import FilterDropdown from "../../../components/filterDropdown/FilterDropdown";
import { makeFinancialYear, makeYearFormate } from "../constantVariable/getFY";
import CloneFYpop from "./CloneYearPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const validatePayload = (payload,discom) => {
  const errors = [];

  if (
    typeof payload.todIncentive !== "object" ||
    payload.todIncentive === null
  ) {
    errors.push("Invalid format for todIncentive data");
    return errors;
  }

  for (const year of Object.keys(payload.todIncentive)) {
    const incentives = payload.todIncentive[year][discom];

    if (!Array.isArray(incentives)) {
      errors.push(`Incentives data for year ${year} is not an array`);
      continue;
    }

    const hasAtLeastOneTOD = incentives.some((incentive) => incentive.incentive !== 0);
    console.log({hasAtLeastOneTOD});
    if (!hasAtLeastOneTOD) {
      errors.push(`At least one ToD slot must be filled for the year ${year}`);
    }

    if (hasAtLeastOneTOD) {
      break;
    }
  }

  return errors;
};

export default function TodIncentives() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [todIncentiveData, setTodIncentiveData] = useState([]);
  const [todIncentiveDataYT, setTodIncentiveDataYT] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateName, setStateName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [noOfTodSlots, setNoOfTodSLots] = useState(1);
  const [validationErrors, setValidationErrors] = useState([]);
  const [financialYear, setFinancialYear] = useState("2024");
  const [clonePopup, setClonePopup] = useState(false);
  const [selectedDisComs, setSelectedDisComs] = useState([]);
  const [disComOptions, setDisComOptions] = useState([]);
  const [disComData, setDisComData] = useState([]);
const [todSlots, setTodSlots] = useState(0);



  useEffect(() => {

     setTodSlots( Array.from(
        { length: noOfTodSlots },
        (_, index) => `ToD ${index + 1}`
      ));
  }, [noOfTodSlots])

  useEffect(() => {
    console.log({todSlots})
  }, [todSlots])
  
  
  useEffect(() => {
    const getStateList = async () => {
      setIsLoading(true);
      const response = await getStateById(id);
      setStateName(response.data.name);
      setStateCode(response.data.code);
      setNoOfTodSLots(response.data.noOfTodSlots[financialYear][selectedDisComs[0]?.value]);
      const data = generateDefaultData(response?.data?.todIncentive,selectedDisComs);
      setTodIncentiveData(data);
      setIsLoading(false);
    };
    getStateList();
  }, [id,selectedDisComs]);

  useEffect(() => {
    if (selectedDisComs.length > 0) {
        setTodIncentiveDataYT(todIncentiveData[financialYear][selectedDisComs[0]?.value]);
    }
  }, [todIncentiveData, financialYear,selectedDisComs]);



  const generateDefaultData = (data, selectedDisComs) => {
    const defaultData = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const hourString = hour.toString().padStart(2, "0");
        const minuteString = minutes.toString().padStart(2, "0");
        defaultData.push({
          hours: `${hourString}:${minuteString}`,
          todName: "",
          tod: "",
          incentive: 0.0,
        });
      }
    }
  
    const result = {};
    const years = [2021, 2022, 2023, 2024];
    years.forEach((year) => {
      if (!result[year]) {
        result[year] = {};
      }
      
      const disComValue = selectedDisComs[0]?.value;
      if (!disComValue) {
        console.warn("No selectedDisCom value found");
        return;
      }
  
      const yearData = [...defaultData];
      if (data && data[year] && data[year][disComValue]) {
        data[year][disComValue].forEach((existingItem) => {
          const index = yearData.findIndex(
            (item) => item.hours === existingItem.hours
          );
          if (index !== -1) {
            yearData[index] = { ...yearData[index], ...existingItem };
          }
        });
      }
  
      result[year][disComValue] = yearData;
    });
  
    return result;
  };
  

  const handleTODChange = (index, value) => {
    const updatedData = [...todIncentiveDataYT];
    updatedData[index].tod = value;
    setTodIncentiveDataYT(updatedData);
  };

  const handleSave = async () => {
    try {
        const payload = {
            code: stateCode,
            todIncentive: {
              ...todIncentiveData,
              [financialYear]: {
                ...(todIncentiveData[financialYear] || {}),
                [selectedDisComs[0]?.value]: todIncentiveDataYT,
              },
            },
          };

      console.log({payload,todIncentiveDataYT});

      const validationErrors = validatePayload(payload,[selectedDisComs[0]?.value]);

     
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => {
          toast.error(error, {
            position: "top-center",
          });
        });
        return;
      }
      const updatedPayload = {
        code: stateCode,
        "discomCode":selectedDisComs[0]?.value ,
        "financialYear":financialYear,
        "todIncentive":todIncentiveDataYT
      }

      const response = await editStateId(updatedPayload);

      toast.success(`${response.message}`);
      setTimeout(() => {
        navigate("/recommendations/tod-banking-policy");
      }, 3000);
    } catch (error) {
      console.error("Error saving TOD Incentives:", error);
      toast.error("Failed to update TOD Incentives");
    }
  };

  const handleCancel = () => {
    navigate("/recommendations/tod-banking-policy");
  };

  const handleFilterChange = (value) => {
    setFinancialYear(value.split("-")[0]);
  };

  useEffect(() => {
    // Fetch data from API
    fetch(`${process.env.REACT_APP_API_URL}/disComCode/list`, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          console.log({ data });

          const initialData = data.data.results;
          const filteredData = initialData.filter(
            (dis) => dis.stateId.code === stateCode
          );
          console.log({ filteredData });
          setDisComData(filteredData);
          const filteredDisComOptions = filteredData.map((disCom) => ({
            label: disCom.code,
            value: disCom.code,
          }));
          setDisComOptions(filteredDisComOptions);
          setSelectedDisComs([
            {
              label: filteredDisComOptions[2].label,
              value: filteredDisComOptions[2].value,
            },
          ]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [stateCode]);

useEffect(() => {
console.log({todIncentiveData});
}, [todIncentiveData])


  return (
    <div className="flex w-[100%]">
      <Sidebar />
      <div className="w-full p-4">
        <ToastContainer position="top-center" autoClose={3000} />
        <div className="flex-grow mb-1">
          <div className="bg-white shadow-md overflow-hidden rounded-xl">
            <div className="p-4 mt-1 w-full flex  justify-between bg-white text-black ">
              <div className="flex  items-center">
                <h2 className="text-lg font-semibold">{`ToD Incentives for ${stateName}`}</h2>
              </div>
              <div className="flex justify-end gap-4">
                <button
                  className="gap-2 bg-[#a9dbc3] overflow-hidden text-black p-2 rounded-md hover:bg-[#daf3e3] hover:text-black hover:px-2 hover:rounded-md flex justify-between items-center"
                  onClick={() => setClonePopup(true)}
                >
                  Clone ToD Incentives
                </button>
                <BackButton />
              </div>
            </div>
            <div className="flex pb-2 gap-2 pl-2  w-full  justify-start">
              <div>
                <FilterDropdown
                  label="FY"
                  options={makeFinancialYear()}
                  selectedValue={makeYearFormate(financialYear)}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="flex ">
                <select
                  value={selectedDisComs[0]?.value || ""} // Set the current value
                  onChange={(e) =>
                    setSelectedDisComs([
                      {
                        label: e.target.options[e.target.selectedIndex].text,
                        value: e.target.value,
                      },
                    ])
                  } // Update state on change
                  className=" max-w-[200px] h-10 px-4 py-2 border border-gray-300 rounded-lg "
                >
                  <option value="">Select DisCom</option>
                  {disComOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="overflow-hidden"
                      title={option.label} // Tooltip for full visibility
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 relative bg-white overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg rounded-lg state-list">
          <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
            <thead className="text-xs uppercase text-black bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 cursor-pointer w-[25%] bg-gray-100 rounded-tl-xl rounded-bl-xl"
                >
                  Hours
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 cursor-pointer w-[25%] bg-gray-100"
                >
                  ToD
                </th>
                <th scope="col" className="px-6 py-4 w-[25%] bg-gray-100">
                  ToD Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 w-[25%] bg-gray-100 rounded-tr-xl rounded-br-xl"
                >
                  Incentive
                </th>
              </tr>
            </thead>
            <tbody>
              {todIncentiveData[financialYear]?.[selectedDisComs[0]?.value]
                ?.length ? (
                todIncentiveData[financialYear][selectedDisComs[0]?.value].map(
                  (policy, index) => (
                    <tr key={index}>
                      <td className="px-6 py-1 text-black">
                        <input
                          type="text"
                          value={policy.hours}
                          onChange={(e) => {
                            const updatedData = [...todIncentiveDataYT];
                            updatedData[index].hours = e.target.value;
                            setTodIncentiveDataYT(updatedData);
                          }}
                        />
                      </td>
                      <td>
                        <select
                          value={policy.tod}
                          onChange={(e) =>
                            handleTODChange(index, e.target.value)
                          }
                          className="px-3 appearance-none w-full bg-white shadow focus:outline-none focus:shadow-outline"
                        >
                          <option value="">Select ToD</option>
                          {todSlots.map((slot) => (
                            <option key={slot} value={slot}>
                              {slot}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="px-4 py-1 text-black">
                        <input
                          type="text"
                          value={policy.todName}
                          className="px-3 w-full bg-white shadow-outline shadow"
                          placeholder="Add Slot Name"
                          onChange={(e) => {
                            const updatedData = [...todIncentiveDataYT];
                            updatedData[index].todName = e.target.value;
                            setTodIncentiveDataYT(updatedData);
                          }}
                        />
                      </td>
                      <td className="px-4 py-1 text-black">
                        <input
                          type="number"
                          value={policy.incentive}
                          className="px-3 w-full bg-white shadow-outline shadow"
                          placeholder="Add Incentive"
                          onChange={(e) => {
                            const updatedData = [...todIncentiveDataYT];
                            updatedData[index].incentive = parseFloat(
                              e.target.value
                            );
                            setTodIncentiveDataYT(updatedData);
                          }}
                        />
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan="4" className="text-center text-gray-500">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {validationErrors.length > 0 && (
            <div className="mt-4 absolute text-red-600 right-4">
              {validationErrors.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
          <div className="flex justify-center gap-8 mt-12">
            <button
              onClick={handleCancel}
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {clonePopup && (
        <CloneFYpop
          label={"ToD Incentives"}
          stateId={id}
          discomCode={selectedDisComs[0]?.value}
          onClose={() => setClonePopup(false)}
          setUpdateCount={() => {}}
        />
      )}
    </div>
  );
}
