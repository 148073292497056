import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useParams } from "react-router-dom";
import {
  addNewPolicy,
  getStateById,
  getStateByStateCode,
} from "../../../services/apis/listings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddPolicyPopUp from "../../../components/addPolicyPopup/AddPolicyPopUp";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../../components/backButton/BackButton";
import RecordsPerPageDropdown from "../../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import DeleteConfirmation from "../../../components/deleteConfirmation/DeleteConfirmation";
import { makeFinancialYear, makeYearFormate } from "../constantVariable/getFY";
import FilterDropdown from "../../../components/filterDropdown/FilterDropdown";
import CloneFYpop from "./CloneYearPopup";

export default function BankingPolicy() {
  const { id } = useParams();
  const [bankingPolicyData, setBankingPolicyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateName, setStateName] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [sortColumn, setSortColumn] = useState("from");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfTodSlots, setNoOfTodSLots] = useState(1);
  const [updateCount, setUpdateCount] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [financialYear, setFinancialYear] = useState("2024");
  const [wholeBankingPolicyData, setWholeBankingPolicyData] = useState([]);
  const [clonePopup, setClonePopup] = useState(false);
  const [disComData, setDisComData] = useState([]);
  const [selectedDisComs, setSelectedDisComs] = useState([]);
  const [disComOptions, setDisComOptions] = useState([]);
  useEffect(() => {
    const getStateList = async () => {
      setIsLoading(true);
      const response = await getStateById(id);
      console.log({response})
      setStateName(response?.data?.name);
      setStateCode(response?.data?.code);
      setNoOfTodSLots( response?.data?.noOfTodSlots?.[financialYear]?.[selectedDisComs[0]?.value] || 0);
      setBankingPolicyData(
        response?.data?.bankingPolicy?.[financialYear]?.[selectedDisComs[0]?.value] || 0
      );
      setWholeBankingPolicyData(response?.data?.bankingPolicy);
      setTotalCount(
        response?.data?.bankingPolicy?.length
          ? response?.data?.bankingPolicy?.length
          : 0
      );
      setIsLoading(false);
    };
    getStateList();
  }, [id, updateCount, financialYear, selectedDisComs]);

  const sortData = (data, column, order) => {
    if (!column) return data;
    if (!data) return [];
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[column] ? a[column].toUpperCase() : "";
      const valueB = b[column] ? b[column].toUpperCase() : "";

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSavePolicy = async (stateId, newPolicy) => {

    console.log({newPolicy})
    let { financialYear, ...rest } = newPolicy;
    let fy = financialYear.split("-")[0];

    let exists = false;


    const fyData = wholeBankingPolicyData?.[fy] || undefined;
    
    
    const discomValue = selectedDisComs[0]?.value || undefined;
    
    
    const discomData = fyData?.[discomValue] || undefined;
    
    if(wholeBankingPolicyData && fyData && discomData && discomValue){
        exists = discomData.some(
            (item) => item.to === rest.to && item.from === rest.from
        );
    }
    

      
    if (exists) return;
    try {
      let result = {
        bankingPolicy: {},
      };
      result.bankingPolicy = {
        ...wholeBankingPolicyData,
      };
      if(wholeBankingPolicyData){
        result.bankingPolicy[fy][selectedDisComs[0]?.value] = [rest, ...(wholeBankingPolicyData[fy][selectedDisComs[0]?.value] || [])];
      }else{
        result.bankingPolicy[fy] = result.bankingPolicy[fy] || {}; // Ensure the fy property exists
    result.bankingPolicy[fy][selectedDisComs[0]?.value] = [rest];
      }

      console.log({result})
      const payload ={
        "discomCode": selectedDisComs[0]?.value,
        "financialYear": fy,
        "bankingPolicy": result.bankingPolicy[fy][selectedDisComs[0]?.value]
      }

      const response = await addNewPolicy(stateId, payload);
      if (response.statusCode === 200) {
        toast.success("New policy added successfully", {
          position: "top-center",
          autoClose: 1500,
        });
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        toast.error("Error while adding policy", {
          position: "top-center",
          autoClose: 1500,
        });
      }

      setBankingPolicyData((prevData) => [...(prevData || []), newPolicy]);
      setUpdateCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Error saving policy:", error);
    }
  };

  const handleRemovePolicy = (policy) => {
    setDeleteModalOpen(true);
    setDeleteItem(policy);
  };

  const confirmDelete = async () => {
    try {
      let fy = financialYear;
      const updatedPolicyData = wholeBankingPolicyData[fy][selectedDisComs[0]?.value].filter(
        (policy) =>
          policy.from !== deleteItem.from || policy.to !== deleteItem.to
      );

      const updatedWholeBankingPolicyData = {  
        ...wholeBankingPolicyData,  
        [fy]: {  
          ...wholeBankingPolicyData[fy],  
          [selectedDisComs[0]?.value]: updatedPolicyData,  
        },  
      };
      console.log({updatedWholeBankingPolicyData});

      setBankingPolicyData(updatedPolicyData);

      let result = {
        discomCode:selectedDisComs[0]?.value,
        financialYear:fy,
        bankingPolicy: updatedWholeBankingPolicyData[fy]?.[selectedDisComs[0]?.value],
      };
      const response = await addNewPolicy(stateCode, result);
      if (response.statusCode === 200) {
        toast.success("Bank policy deleted successfully", {
          position: "top-center",
          autoClose: 1500,
        });
        setUpdateCount((prevCount) => prevCount + 1);
      } else {
        toast.error("Bank policy could not be deleted", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } catch (error) {
      console.error("Error removing policy:", error);
      toast.error("Error removing policy", {
        position: "top-center",
        autoClose: 1500,
      });
    } finally {
      setDeleteModalOpen(false);
      setDeleteItem(null);
    }
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const handleFilterChange = (value) => {
    setFinancialYear(value.split("-")[0]);
  };

  const sortedData = sortData(bankingPolicyData, sortColumn, sortOrder);

  useEffect(() => {
    // Fetch data from API
    fetch(`${process.env.REACT_APP_API_URL}/disComCode/list`, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          console.log({ data });

          const initialData = data.data.results;
          const filteredData = initialData.filter(
            (dis) => dis.stateId.code === stateCode
          );
          console.log({ filteredData });
          setDisComData(filteredData);
          const filteredDisComOptions = filteredData.map((disCom) => ({
            label: disCom.code,
            value: disCom.code,
          }));
          setDisComOptions(filteredDisComOptions);
          setSelectedDisComs([
            {
              label: filteredDisComOptions[2].label,
              value: filteredDisComOptions[2].value,
            },
          ]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [stateCode]);

  // Handle change in selected options

  // Map the DisCom data to a suitable format for the multi-select

  return (
    <div className="flex w-[100%]">
      <Sidebar />
      <div className="w-full p-4">
        <div className="flex-grow mb-1">
          <div className="bg-white shadow-sm  rounded-xl">
            <div className="">
              <div className="flex w-full p-2 justify-between items-center">
                <div className="text-lg w-full inline font-semibold">{`Banking Policy for ${stateName}`}
                </div>

                <div className=" flex justify-end gap-2 w-full">
                  <button
                    className="gap-2 bg-[#a9dbc3] overflow-hidden text-black p-2 rounded-md hover:bg-[#daf3e3] hover:text-black hover:px-2 hover:rounded-md flex justify-between items-center"
                    onClick={() => setClonePopup(true)}
                  >
                    Clone policy
                  </button>
                  <button
                    className="w-[7rem] bg-[#a9dbc3] overflow-hidden text-black p-2 rounded-md hover:bg-[#daf3e3] hover:text-black hover:px-2 hover:rounded-md flex justify-between items-center"
                    onClick={openModal}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add policy
                  </button>
                  <div className="max-w-[6rem]">
                    <BackButton />
                  </div>
                </div>
              </div>
              <div>
                {" "}
                <div className="flex justify-start gap-4 pl-4 pb-4">
                  <FilterDropdown
                    label="FY"
                    options={makeFinancialYear()}
                    selectedValue={makeYearFormate(financialYear)}
                    onChange={handleFilterChange}
                  />

                  <div className="flex ">
                
                    <select
                      value={selectedDisComs[0]?.value || ""} // Set the current value
                      onChange={(e) =>
                        setSelectedDisComs([
                          {
                            label:
                              e.target.options[e.target.selectedIndex].text,
                            value: e.target.value,
                          },
                        ])
                      } // Update state on change
                      className=" max-w-[200px] h-10 px-4 py-2 border border-gray-300 rounded-lg "
                    >
                      <option value="">Select DisCom</option>
                      {disComOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="overflow-hidden"
                          title={option.label} // Tooltip for full visibility
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-4 py-4">
                    <RecordsPerPageDropdown onRecordsPerPageChange={handleRecordsPerPageChange} />
                </div> */}
        <div className="p-2 py-4 mt-4 bg-white relative overflow-x-auto overflow-y-auto sm:rounded-lg rounded-lg state-list">
          <table className="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400">
            <thead className="text-xs uppercase text-black bg-gray-100">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider cursor-pointer w-[10%] rounded-tl-xl rounded-bl-xl"
                  onClick={() => handleSort("from")}
                >
                  Sr no.
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider cursor-pointer w-[40%]"
                  onClick={() => handleSort("from")}
                >
                  From{" "}
                  {sortColumn === "from" && (sortOrder === "asc" ? "▲" : "▼")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider cursor-pointer w-[40%]"
                  onClick={() => handleSort("to")}
                >
                  To {sortColumn === "to" && (sortOrder === "asc" ? "▲" : "▼")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 bg-gray-100 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider cursor-pointer w-[10%] rounded-tr-xl rounded-br-xl"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && sortedData.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center text-black">
                    No data available
                  </td>
                </tr>
              ) : (
                sortedData.map((state, index) => (
                  <tr className="border-b text-black" key={index}>
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4 font-medium whitespace-nowrap flex items-center justify-left">
                      {state.from}
                    </td>
                    <td className="px-6 py-4 font-medium whitespace-nowrap">
                      {state.to}
                    </td>
                    <td className="px-6 py-4 font-medium whitespace-nowrap justify-center">
                      <button
                        className="mx-1"
                        onClick={() => handleRemovePolicy(state)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* {totalCount > limit && (
                        <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                            <button className={`bg-[#e1e0e0] ${currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl`} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                            <span className="mx-4">{Math.min(currentPage * limit, totalCount) ? Math.min(currentPage * limit, totalCount) : '0'} off {totalCount ? totalCount : '0'}</span>
                            <button className={`bg-[#e1e0e0] ${currentPage * limit >= totalCount ? "bg-[#f0f0f0] text-gray-500" : ''} p-3 rounded-xl mr-2`} disabled={currentPage * limit >= totalCount} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                        </div>
                    )} */}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
      <AddPolicyPopUp
        isOpen={isModalOpen}
        closeModal={closeModal}
        onSave={handleSavePolicy}
        stateId={id}
        stateCode={stateCode}
        noOfTodSlots={noOfTodSlots}
      />
      <DeleteConfirmation
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        itemName={deleteItem ? `${deleteItem.from} to ${deleteItem.to}` : ""}
      />
      {clonePopup && (
        <CloneFYpop
          label={"Policy"}
          stateId={id}
          discomCode={selectedDisComs[0]?.value}
          onClose={() => setClonePopup(false)}
          setUpdateCount={() => setUpdateCount((prev) => prev + 1)}
        />
      )}
    </div>
  );
}
