import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import readXlsxFile from "read-excel-file";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import ExcelUploadButton from "../../../components/excelDownload/ExcelUploadButton";
import BackButton from "../../../components/backButton/BackButton";
import Sidebar from "../../../components/sidebar/Sidebar";
import RecordsPerPageDropdown from "../../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import ResetButton from "../../../components/resetButton/ResetButton";
import {
  addIexDiscountData,
  addSingleIexDiscountData,
  deleteExistingIexDiscountRecord,
  fetchIexDiscountDataList,
  getSingleIexDiscountData,
  getStateById,
  updateSingleIexDiscountData,
} from "../../../services/apis/iexDisountData";
import IexDiscountDataTable from "../../../components/table/IexDiscountDataTable";
import ExcelDownloadButtonForIexDiscount from "../../../components/excelDownload/ExcelDownloadButtonForIexDiscount";

const IEXDiscountDataList = () => {
  const [startDate, setStartDate] = useState("");
  const [addStartDate, setAddStartDate] = useState("");
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem("recordsPerPage"), 10);
    return storedLimit || 50;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "_id",
    direction: "asc",
  });
  const [iexGenData, setIexGenData] = useState([]);
  const [stateData, setStateData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [eExcel, setEExcel] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uniqueDates, setUniqueDates] = useState([]);
  const [excelIEXDataGenerationData, setExcelIEXDataGenerationData] =
    useState();
  const [listCountUpdate, setListUpdateCount] = useState(0);

  const [noOfTodSlots, setNoOfTodSlots] = useState(0);
  const [stateCode, setStateCode] = useState("");
  const [slotData, setSlotData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isTablePopupOpen, setIsTablePopupOpen] = useState(false);
  const [addStartDateError, setAddStartDateError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDisComs, setSelectedDisComs] = useState([]);
  const [disComOptions, setDisComOptions] = useState([]);
  const [disComData, setDisComData] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  const [updateData, setUpdateData] = useState(false);

  const toggleTablePopup = () => setIsTablePopupOpen(!isTablePopupOpen);

  const fetchData = async () => {
    const payload = {
      month: startDate !== "" ? formatDateToMonth(startDate) : null,
      page: currentPage,
      pageSize: limit,
      sortBy: sortConfig.key,
      sortOrder: sortConfig.direction,
      stateId: id,
      discomCode: selectedDisComs[0]?.value,
    };
    const PowerPlantDataList = await fetchIexDiscountDataList(payload);
    const data = PowerPlantDataList?.data?.data?.results;
    setIexGenData(data);
    setTotalCount(PowerPlantDataList?.data?.data?.total);
  };

  useEffect(() => {
    // Fetch data from API
    fetch(`${process.env.REACT_APP_API_URL}/disComCode/list`, {
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          console.log({ data });

          const initialData = data.data.results;
          const filteredData = initialData.filter(
            (dis) => dis.stateId.code === stateCode
          );
          console.log({ filteredData });
          setDisComData(filteredData);
          const filteredDisComOptions = filteredData.map((disCom) => ({
            label: disCom.code,
            value: disCom.code,
          }));
          setDisComOptions(filteredDisComOptions);
          setSelectedDisComs([
            {
              label: filteredDisComOptions[2].label,
              value: filteredDisComOptions[2].value,
            },
          ]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [stateCode]);

  useEffect(() => {
    const fetchStateData = async () => {
      const stateData = await getStateById(id);
      setStateData(stateData?.data?.data);
    //   const noOfTod = stateData?.data?.data?.noOfTodSlots;
    //   setNoOfTodSlots(noOfTod);
      setStateCode(stateData?.data?.data?.code);
    };

    fetchStateData();
  }, [id]);


  const getNoOfTodSlotsUsingDate =(date)=>{
    console.log({date});
    const yearOfDate = new Date(date).getFullYear() || 2024;
    console.log({stateData});
    const todSlots = 
    stateData?.noOfTodSlots?.[yearOfDate]?.[selectedDisComs?.[0]?.value] ?? 0;   
     if(todSlots === 0 ){
        toast.error("For Current Discom and  year no of tod slot is not available!");
        
        return ;
    }else{
        return todSlots;
    }

     
  }

  useEffect(() => {
    if(selectedDisComs.length > 0) {
    fetchData();
    }
  }, [startDate, currentPage, limit, listCountUpdate,selectedDisComs]);

  const formatDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'00:00:00.000'Z'");
    return formattedDate;
  };

  // Function to toggle popup visibility
  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  function formatDateToddmmyyyy(date) {
    let d = new Date(date);
    let day = ("0" + d.getDate()).slice(-2);
    let month = ("0" + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    return `${year}-${month}-${day}`;
  }

  const formatDateToMonth = (date) => {
    if (!date) return null;

    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date");
    }

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");

    return `${year}-${month}`;
  };

  const handleSave = async () => {
    if (!addStartDate) {
      setAddStartDateError("Please fill date");
      return;
    }

    const noOfTodSlots  = getNoOfTodSlotsUsingDate(addStartDate);
    const completeSlotData = {};
    for (let i = 1; i <= noOfTodSlots; i++) {
      completeSlotData[`slot-${i}`] = slotData[`slot-${i}`] || 0;
    }
    const month = formatDateToMonth(addStartDate);
    console.log({completeSlotData})
    console.log({selectedID})
    let payload = {};
    if (updateData) {
     payload = {
      month,
      stateId: id,
      stateCode,
      discomCode:selectedDisComs[0]?.value,
      data: completeSlotData,
    };
}else{

    const discomCodeData = disComData?.find((dis) => dis.code === selectedDisComs[0]?.value);
    console.log({discomCodeData,disComData});
    payload = {
      month,
      stateId: id,
      stateCode,
      discomCode:selectedDisComs[0]?.value,
      data: completeSlotData,
      discomId:discomCodeData?._id
    };
}
    try {
        let response;

        if (updateData) {
             response = await updateSingleIexDiscountData(selectedID,payload);
        } else {
            response = await addSingleIexDiscountData(payload);
        }
    

      if (response.data.statusCode === 200 || response.data.statusCode === 201) {
        toast.success("IEX discount saved successfully");
        setIsPopupOpen(false);
        setSlotData({});
        setAddStartDate("");
        setAddStartDateError("");
        setListUpdateCount((prevValue) => prevValue + 1);
      } else {
        toast.error("Data not updated!");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error in save data:");
    }
  };

  const handleSlotChange = (index, value) => {
    setSlotData((prev) => ({
      ...prev,
      [`slot-${index + 1}`]: value ? parseFloat(value) : 0,
    }));
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleDelete = async (id) => {
    console.log({id});
    const deletedData = await deleteExistingIexDiscountRecord(id);
    if (deletedData.status === 200) {
      setListUpdateCount((prevValue) => prevValue + 1);
      setIsTablePopupOpen(!isTablePopupOpen);
      toast.success("IEX discount deleted successfully");
    } else {
      toast.error("Error while deleting IEX discount deleted");
    }
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const [iexGenDataToUpload, setIexGenDataToUpload] = useState([]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const extension = file.name.split(".").pop();

      if (extension === "xlsx") {
        setLoader(true);
        setEExcel("");
        try {
          const excelIEXDataGenerationData = await readXlsxFile(file);

          const [headers, ...rows] = excelIEXDataGenerationData;

          const months = headers.slice(1);

          const payloads = [];

          months.forEach((month, index) => {
            const data = {};

            rows.forEach((row) => {
              const slotName = row[0];
              const value = row[index + 1];

              if (value !== undefined) {
                data[slotName] = value;
              }
            });

            const payload = {
              month: formatDateToMonth(month),
              stateId: id,
              stateCode: stateCode,
              data,
            };

            payloads.push(payload);
          });

          setIexGenDataToUpload(payloads);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        } finally {
          setLoader(false);
        }
      } else {
        setEExcel("Invalid file type. Please upload an Excel file (.xlsx).");
      }
    } else {
      setEExcel("No file selected.");
    }
  };

  const handleExcelSave = async () => {
    try {
      setLoader(true);
      const response = await addIexDiscountData(iexGenDataToUpload);
      if (response.status === 201) {
        setLoader(false);
        toast.success("Excel data saved successfully");
        setIsUploadPopupOpen(false);
        setListUpdateCount((count) => count + 1);
      }
    } catch (error) {
      console.error("Error handling Excel save:", error);
      toast.error("Error handling Excel save. Please try again.");
    }
  };

  const formatMonth = (yearMonth) => {
    if (!yearMonth) return null;
    const [year, month] = yearMonth.split("-").map(Number);
    return new Date(year, month - 1, 1);
  };

  const handleUpdate = async (IexDiscountId) => {
    console.log({IexDiscountId})
    const fetchIexDiscountData = async () => {
      const iexDiscountDataResponse = await getSingleIexDiscountData(
        IexDiscountId
      );
      const iexDiscountData = iexDiscountDataResponse.data.data;
      setStateCode(iexDiscountData.stateCode);
      setAddStartDate(formatDate(iexDiscountData.month));
      setSlotData(iexDiscountData.data);
      setIsPopupOpen(true);
      setSelectedID(IexDiscountId);
      setUpdateData(true);
      
    };

    fetchIexDiscountData();
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-[1920px]">
          <div className="flex-grow mb-1">
            <div className=" overflow-hidden rounded-xl">
              <div className="p-4 mt-1 rounded-xl shadow-sm bg-white text-black ">
                <div className="w-full flex justify-between">
                  <div>
                    <h2 className="text-lg font-semibold">{`IEX Discount List`}</h2>
                  </div>
                  <div className="flex  items-center gap-4">
                    <ExcelUploadButton
                      setIsUploadPopupOpen={setIsUploadPopupOpen}
                    />
                    {isUploadPopupOpen && (
                      <div className="fixed z-20  top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80">
                        <div className="bg-white p-5 rounded shadow-lg w-fit">
                          <div className="mt-2">
                            <ExcelDownloadButtonForIexDiscount />
                          </div>
                          <div className="flex flex-row mt-10">
                            <div className="font-semibold mr-2 mt-2">
                              Upload IEX Discount Data :{" "}
                            </div>
                            <div className="flex flex-col">
                              <input
                                id="file-input"
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileUpload}
                                className="text-[14px] w-[100%] bg-white border  pl-[20px] rounded-[10px] h-[40px] pt-[6px] border-[#B9B9B9]"
                              />
                              {loader && (
                                <div className="loader-container">
                                  <div className="loader"></div>
                                  <div>Wait uploading data</div>
                                </div>
                              )}
                              {eExcel && (
                                <p className="text-red-500 text-sm">{eExcel}</p>
                              )}
                            </div>
                          </div>
                          <div className="flex gap-8 justify-center mt-8">
                            <button
                              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                              onClick={() => {
                                setIsUploadPopupOpen(false);
                                setEExcel("");
                                setSelectedFile(null);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                              onClick={handleExcelSave}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="border cursor-pointer bg-[#a9dbc3] py-2 px-2 p-[5px] rounded-lg">
                      {" "}
                      <FontAwesomeIcon
                        className=" focus:border-none"
                        onClick={togglePopup}
                        id="addtooltip"
                        size="xl"
                        icon={faAdd}
                      />
                      <Tooltip
                        anchorSelect="#addtooltip"
                        content="Add Power Plant Data"
                      />
                      {isPopupOpen && (
                        <div className="fixed z-20  top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80">
                          <div className="bg-white p-5 rounded-xl shadow-lg w-96">
                            <h2 className="text-lg font-semibold">
                              Select Date
                            </h2>
                            <div className="mt-4 w-full flex powerPlant ">
                              <DatePicker
                                className="border border-gray-400 w-full px-2 py-1 rounded-lg"
                                placeholderText="Please Select Date"
                                selected={addStartDate}
                                dateFormat="dd-MM-yyyy"
                                
                                onChange={(date) => setAddStartDate(date)}
                              />
                              <FontAwesomeIcon
                                icon={faCalendarAlt}
                                className="mt-2 -ml-6 z-50 text-gray-500"
                              />
                            </div>
                            {addStartDateError !== "" ? (
                              <div className="text-red-500 capitalize px-[20px] py-[1px]">
                                {addStartDateError}
                              </div>
                            ) : (
                              ""
                            )}
                            <div></div>
                            <div>
                              {Array.from(
                                { length: getNoOfTodSlotsUsingDate(addStartDate) },
                                (_, index) => (
                                  <div
                                    key={index}
                                    className="mt-4 flex flex-row w-[100%] items-center"
                                  >
                                    <label
                                      htmlFor={`slot-${index + 1}`}
                                      className="block text-sm font-medium text-gray-700 w-[30%]"
                                    >
                                      Slot-{index + 1} :
                                    </label>
                                    <div className="w-[50%]">
                                      <input
                                        type="number"
                                        id={`slot-${index + 1}`}
                                        name={`slot-${index + 1}`}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#067C4E] focus:border-[#067C4E] sm:text-sm"
                                        value={
                                          slotData[`slot-${index + 1}`] ||
                                          0 ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleSlotChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="flex gap-8 justify-center mt-8">
                              <button
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 "
                                onClick={() => {
                                  togglePopup();
                                  setAddStartDate("");
                                  setSlotData({});
                                  setAddStartDate("");
                                  setAddStartDateError("");
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 "
                                onClick={handleSave}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <BackButton />
                  </div>
                </div>
                <div>
                  <div className="flex ">
                    <select
                      value={selectedDisComs[0]?.value || ""} // Set the current value
                      onChange={(e) =>
                        setSelectedDisComs([
                          {
                            label:
                              e.target.options[e.target.selectedIndex].text,
                            value: e.target.value,
                          },
                        ])
                      } // Update state on change
                      className=" max-w-[200px] h-10 px-4 py-2 border border-gray-300 rounded-lg "
                    >
                      <option value="">Select DisCom</option>
                      {disComOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="overflow-hidden"
                          title={option.label} // Tooltip for full visibility
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-xl">
            <div className="px-2 py-4 overflow-hidden mt-4 rounded-t-xl min-h-[500px]">
              <div className="flex gap-6 px-4">
                <div className=" ">
                  <DatePicker
                    className="border border-gray-400 px-2 py-1 rounded-lg"
                    placeholderText="Please Select Date"
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <RecordsPerPageDropdown
                  onRecordsPerPageChange={handleRecordsPerPageChange}
                />
                <div className="ml-[580px] " onClick={() => setStartDate("")}>
                  <ResetButton />
                </div>
              </div>
              <div className="bg-white p-2 rounded-xl min-h-[350px] mt-6">
                <IexDiscountDataTable
                  id={id}
                  isTablePopupOpen={isTablePopupOpen}
                  toggleTablePopup={toggleTablePopup}
                  isChecked={isChecked}
                  handleCheckboxChange={handleCheckboxChange}
                  data={iexGenData}
                  handleDelete={handleDelete}
                  startDate={startDate}
                  handleUpdate={handleUpdate}
                />
              </div>
              {totalCount > limit && (
                <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                  <button
                    className={`bg-[#e1e0e0] ${
                      currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ""
                    } p-3 rounded-xl`}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-4">
                    {Math.min(currentPage * limit, totalCount)
                      ? Math.min(currentPage * limit, totalCount)
                      : "0"}{" "}
                    off {totalCount ? totalCount : "0"}
                  </span>
                  <button
                    className={`bg-[#e1e0e0] ${
                      currentPage * limit >= totalCount
                        ? "bg-[#f0f0f0] text-gray-500"
                        : ""
                    } p-3 rounded-xl mr-2`}
                    disabled={currentPage * limit >= totalCount}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default IEXDiscountDataList;
