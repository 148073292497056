import {
  faListUl,
  faPlus,
  faSitemap,
  faNetworkWired,
  faMapLocationDot,
  faIndustry,
  faWarehouse,
  faGauge,
  faScaleBalanced,
  faSliders,
  faTowerCell,
  faFileContract,
  faDiagramProject,
  faCalculator,
  faSquareRootVariable,
  faGears,
  faThumbtack,
} from "@fortawesome/free-solid-svg-icons";

const config = {
  sidebar: {
    UserRole: [
      { title: "List", link: "/user-role-list", icon: faListUl },
      { title: "Add", link: "/add-user-role", icon: faPlus },
    ],
    UserList: [
      { title: "List", link: "/user-list", icon: faListUl },
      // { title: 'Add', link: '/add-user' },
    ],
    CMS: [
      { title: "List", link: "/content-list", icon: faListUl },
      { title: "Add", link: "/add-content", icon: faPlus },
    ],
    Analytics: [
      {
        title: "Organisation List",
        link: "/organisation-list",
        icon: faSitemap,
      },
      { title: "Factory List", link: "/factory-list", icon: faIndustry },
      { title: "Unit List", link: "/units-list", icon: faWarehouse },
      { title: "Meter List", link: "/meters-list", icon: faGauge },
      { title: "State List", link: "/state-list", icon: faMapLocationDot },
      { title: "Discom List", link: "/discom-list", icon: faNetworkWired },
    ],
    Recommendations: [
      {
        title: "Billing Units",
        link: "/recommendations/billing-unit-list",
        icon: faCalculator,
      },
      {
        title: "Global Variables",
        link: "/recommendations/global-variables-list",
        icon: faSliders,
      },
      {
        title: "Regulatory Charges",
        link: "/recommendations/regulatory-charges-list",
        icon: faScaleBalanced,
      },
      {
        title: "Power Plant Types",
        link: "/recommendations/powerplant-type-list",
        icon: faGears,
      },
      {
        title: "Power Plants",
        link: "/recommendations/powerplant-list",
        icon: faTowerCell,
      },
      {
        title: "ToD Banking Policy",
        link: "/recommendations/tod-banking-policy",
        icon: faFileContract,
      },
      {
        title: "Intermediate Variables",
        link: "/recommendations/intermediate-variables",
        icon: faDiagramProject,
      },
      {
        title: "Constant Variables",
        link: "/recommendations/constant-variables",
        icon: faThumbtack,
      },
      {
        title: "Formula",
        link: "/recommendations/formula/list",
        icon: faSquareRootVariable,
      },
      {
        title: "Unit List",
        link: "/recommendations/unit-list",
        icon: faWarehouse,
      },
    ],
  },

  userSettings: {
    fields: {
      username: {
        label: "User Name",
        type: "text",
        required: "true",
      },
      email: {
        label: "Email",
        type: "email",
        required: "true",
      },
      password: {
        label: "Password",
        type: "password",
        required: "true",
      },
      mobile: {
        label: "Mobile No.",
        type: "tel",
        required: "true",
      },
      status: {
        label: "Status",
        type: "select",
        options: ["active", "inactive"],
        required: "true",
      },
      userType: {
        label: "User Type",
        type: "select",
        options: ["buyer", "seller"],
        required: "true",
      },
      // userRole : {
      //     label: 'User Role',
      //     type: 'select',
      //     multiple: true,
      //     options: ['admin', 'super admin', 'executive', 'sales', 'marketing'],
      //     required: 'true'
      // },
    },
  },

  userListTableHeadings: {
    _id: "Id",
    username: "Name",
    email: "Email",
    userType: "Type",
    status: "Status",
    roles: "Roles",
    addRole: "Add Role",
    actions: "Actions",
  },

  userRoleSettings: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      uniqueKey: {
        label: "Unique Key",
        type: "text",
        required: true,
      },
      status: {
        label: "Status",
        type: "select",
        options: ["active", "inactive"],
        required: true,
      },
    },
  },

  discom: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      code: {
        label: "Code",
        type: "text",
        required: true,
      },
      stateId: {
        label: "State Id",
        type: "selectApi",
        required: true,
      },
      status: {
        label: "Status",
        type: "select",
        options: ["active", "inactive"],
        required: true,
      },
    },
  },

  userRoleListTableHeadings: {
    _id: "Id",
    name: "Name",
    uniqueKey: "Unique Key",
    status: "Status",
    actions: "Actions",
  },

  cmsSettings: {
    blog: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        shortDescription: {
          label: "Short Description",
          type: "textarea",
          required: true,
        },
        addProfileImageButton: {
          label: "Add Profile Image",
          type: "button",
        },
        profileImage: {
          label: "Profile Image",
          type: "image",
          showSelectedImage: true,
          required: true,
        },
        addImageButton: {
          label: "Add Image",
          type: "button",
        },
        description: {
          label: "Description",
          type: "richText",
          required: true,
        },
        metaTitle: {
          label: "Meta Title",
          type: "text",
          required: false,
        },
        metaDataDescription: {
          label: "Meta Data Description",
          type: "text",
          required: false,
        },
        metaKeyword: {
          label: "Meta Keyword",
          type: "text",
          required: false,
        },
        authorName: {
          label: "Author",
          type: "text",
          required: false,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    articles: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        shortDescription: {
          label: "Short Description",
          type: "textarea",
          required: true,
        },
        addProfileImageButton: {
          label: "Add Profile Image",
          type: "button",
        },
        profileImage: {
          label: "Profile Image",
          type: "image",
          showSelectedImage: true,
          required: true,
        },
        addImageButton: {
          label: "Add Image",
          type: "button",
        },
        description: {
          label: "Description",
          type: "richText",
          required: true,
        },
        metaTitle: {
          label: "Meta Title",
          type: "text",
          required: false,
        },
        metaDataDescription: {
          label: "Meta Data Description",
          type: "text",
          required: false,
        },
        metaKeyword: {
          label: "Meta Keyword",
          type: "text",
          required: false,
        },
        authorName: {
          label: "Author",
          type: "text",
          required: false,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    solution: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        shortDescription: {
          label: "Short Description",
          type: "textarea",
          required: true,
        },
        addProfileImageButton: {
          label: "Add Profile Image",
          type: "button",
        },
        profileImage: {
          label: "Profile Image",
          type: "image",
          showSelectedImage: true,
          required: true,
        },
        addImageButton: {
          label: "Add Image",
          type: "button",
        },
        description: {
          label: "Description",
          type: "richText",
          required: true,
        },
        metaTitle: {
          label: "Meta Title",
          type: "text",
          required: false,
        },
        metaDataDescription: {
          label: "Meta Data Description",
          type: "text",
          required: false,
        },
        metaKeyword: {
          label: "Meta Keyword",
          type: "text",
          required: false,
        },
        authorName: {
          label: "Author",
          type: "text",
          required: false,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    page: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        // shortDescription: {
        //     label: 'Short Description',
        //     type: 'textarea',
        //     required: true,
        // },
        // addProfileImageButton: {
        //     label: "Add Profile Image",
        //     type: "button",
        // },
        // profileImage: {
        //     label: "Profile Image",
        //     type: "image",
        //     showSelectedImage: true,
        //     required: true,
        // },
        addImageButton: {
          label: "Add Image",
          type: "button",
        },
        description: {
          label: "Description",
          type: "richText",
          required: true,
        },
        metaTitle: {
          label: "Meta Title",
          type: "text",
          required: false,
        },
        metaDataDescription: {
          label: "Meta Data Description",
          type: "text",
          required: false,
        },
        metaKeyword: {
          label: "Meta Keyword",
          type: "text",
          required: false,
        },
        pageName: {
          label: "Page Name",
          type: "text",
          required: false,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    career: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        addImageButton: {
          label: "Add Image",
          type: "button",
        },
        description: {
          label: "Description",
          type: "richText",
          required: true,
        },
        metaTitle: {
          label: "Meta Title",
          type: "text",
          required: false,
        },
        metaDataDescription: {
          label: "Meta Data Description",
          type: "text",
          required: false,
        },
        metaKeyword: {
          label: "Meta Keyword",
          type: "text",
          required: false,
        },
        // pageName: {
        //     label: 'Page Name',
        //     type: 'text',
        //     required: false,
        // },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    faqs: {
      fields: {
        contentCategory: {
          label: "Category",
          type: "select",
          // options: ['draft','published', 'deleted'],
          required: true,
        },
        title: {
          label: "Question",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        description: {
          label: "Answer",
          type: "textarea",
          required: true,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
    homePage: {
      fields: {
        title: {
          label: "Title",
          type: "text",
          required: true,
        },
        slug: {
          label: "Slug",
          type: "text",
          required: true,
        },
        description: {
          label: "Description",
          type: "textarea",
          required: true,
        },
        heading: {
          label: "Heading",
          type: "text",
          required: true,
        },
        headingDescription: {
          label: "Heading Description",
          type: "textarea",
          required: true,
        },
        feature_ci_buyer: {
          label: "Buyer Description",
          type: "textarea",
          required: true,
        },
        feature_power_sellers: {
          label: "Power Sellers Description",
          type: "textarea",
          required: true,
        },
        feature_power_traders: {
          label: "Power Traders Description",
          type: "textarea",
          required: true,
        },
        aboutOurTeam: {
          label: "About Our Team",
          type: "textarea",
          required: true,
        },
        status: {
          label: "Status",
          type: "select",
          options: ["draft", "published", "deleted"],
          required: true,
        },
      },
    },
  },

  cmsListTableHeadings: {
    _id: "Id",
    type: "Type",
    title: "Title",
    createdBy: "Created By",
    date: "Date",
    status: "Status",
    actions: "Actions",
  },

  orgListTableHeadings: {
    _id: "Id",
    name: "Organisation Name",
    viewEditDeleteactions: "Actions",
  },

  factoryListTableHeadings: {
    _id: "Id",
    name: "Factory Name",
    viewActions: "Actions",
  },

  unitsListTableHeadings: {
    _id: "Id",
    name: "Unit Name",
    viewActions: "Actions",
  },

  metersListTableHeadings: {
    _id: "Id",
    meterNo: "Meter No.",
    consumerNo: "Consumer No.",
    meterActions: "Actions",
  },

  ocrListTableHeadings: {
    _id: "Id",
    meterNo: "Meter No.",
    consumerNo: "Consumer No.",
    consumerName: "Consumer Name",
    readingDate: "Reading Date",
    viewActions: "Actions",
  },

  billingUnitSettings: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      symbol: {
        label: "Symbol",
        type: "text",
        required: true,
      },
      status: {
        label: "Status",
        type: "select",
        options: ["active", "inactive"],
        required: false,
      },
    },
  },

  billingUnitListHeaders: {
    _id: "Id",
    name: "Billing Unit Name",
    symbol: "Symbol",
    status: "Status",
    billingUnitActions: "Actions",
  },

  globalRecommendationVariableSettings: {
    global: {
      fields: {
        name: {
          label: "Name",
          type: "text",
          required: true,
        },
        uniqueName: {
          label: "Unique Name",
          type: "text",
          required: true,
        },
        unit: {
          label: "Unit",
          type: "select",
          // options: ["Rs/kWh", "Rs/kwH", "Rs/MW/month", "%", "Rs/KVAh", "Rs/KVA", "Rs./YEAR", "Rs."],
          required: true,
        },
        type: {
          label: "Type",
          type: "select",
          options: [
            "Regulatory Charge",
            "Intermediate Variable",
            "Formula",
            "Constant",
          ],
          required: true,
        },
        // value: {
        //     label: 'Value',
        //     type: 'text',
        //     required: true,
        // },
        status: {
          label: "Status",
          type: "select",
          options: ["active", "inactive"],
          required: false,
        },
      },
    },
  },

  globalRecommendationVariablesListHeaders: {
    _id: "Id",
    name: "Variable Name",
    uniqueName: "Unique Name",
    type: "Type",
    unit: "Unit",
    isMap: "Is Mapped",
    status: "Status",
    globalActions: "Actions",
  },

  regulatoryChargesSettings: {
    central: {
      fields: {
        globalRecVariableId: {
          label: "Global Variable",
          type: "select",
          required: true,
        },
        discomCharges: {
          label: "Discom Charges",
          type: "number",
          required: true,
        },
        thirdPartyCharges: {
          label: "Third Party Charges",
          type: "number",
          required: true,
        },
        captiveCharges: {
          label: "Captive Charges",
          type: "number",
          required: true,
        },
        rtsCharges: {
          label: "RTS Charges",
          type: "number",
          required: true,
        },
        // status: {
        //     label: 'Status',
        //     type: 'select',
        //     options: ['active', 'inactive'],
        //     required: true,
        // },
      },
    },
    state: {
      fields: {
        globalRecVariableId: {
          label: "Global Variable",
          type: "select",
          // options: ['active', 'inactive'],
          required: true,
        },
        objectId: {
          label: "State",
          type: "select",
          // options: ['draft', 'published', 'deleted'],
          required: true,
        },
        discomCharges: {
          label: "Discom Charges",
          type: "number",
          required: true,
        },
        thirdPartyCharges: {
          label: "Third Party Charges",
          type: "number",
          required: true,
        },
        captiveCharges: {
          label: "Captive Charges",
          type: "number",
          required: true,
        },
        rtsCharges: {
          label: "RTS Charges",
          type: "number",
          required: true,
        },
        // status: {
        //     label: 'Status',
        //     type: 'select',
        //     options: ['active', 'inactive'],
        //     required: true,
        // },
      },
    },
    discom: {
      fields: {
        globalRecVariableId: {
          label: "Global Variable",
          type: "select",
          // options: ['active', 'inactive'],
          required: true,
        },
        stateId: {
          label: "State",
          type: "select",
          // options: ['draft', 'published', 'deleted'],
          required: true,
        },
        objectId: {
          label: "Discom",
          type: "select",
          // options: ['draft', 'published', 'deleted'],
          required: true,
        },
        tariffCategory: {
            label: "Tariff Category",
            type: "select",
            required: true,
            options: [
              "HT I Industry",
              "HT II Commercial",
              "HT III Group Housing Societies",
              "HT VA Public Services Govt",
            ],
          },
        discomCharges: {
          label: "Discom Charges",
          type: "number",
          required: true,
        },
        thirdPartyCharges: {
          label: "Third Party Charges",
          type: "number",
          required: true,
        },
        captiveCharges: {
          label: "Captive Charges",
          type: "number",
          required: true,
        },
        rtsCharges: {
          label: "RTS Charges",
          type: "number",
          required: true,
        },
        // status: {
        //     label: 'Status',
        //     type: 'select',
        //     options: ['active', 'inactive'],
        //     required: true,
        // },
      },
    },
  },

  regulatoryChargesListHeaders: {
    _id: "Id",
    // globalRecVariableId: "Global Variable",
    variableName: "Variable Name",
    uniqueName: "Unique Name",
    financialYear: "Financial Year",
    objectType: "Variable Type",
    state: "State",
    discom: "Discom",
    discomCharges: "Discom Charges",
    thirdPartyCharges: "Third Party Charges",
    captiveCharges: "Captive Charges",
    rtsCharges: "RTS Charges",
    status: "Status",
    actions: "Actions",
  },

  intermediateVariablesListHeaders: {
    _id: "Id",
    globalRecVariableId: "Global Variable",
    modelName: "Collection Name",
    mapping: "Mapping",
    description: "Description",
    actions: "Actions",
  },

  intermediateVariablesSettings: {
    fields: {
      globalRecVariableId: {
        label: "Global Variable",
        type: "select",
        required: true,
      },
      modelName: {
        label: "Collection Name",
        type: "select",
        required: true,
      },
      mapping: {
        label: "Collection Field Name",
        type: "select",
        required: true,
      },
      description: {
        label: "Description",
        type: "text",
      },
    },
  },

  constantVariablesSettings: {
    fields: {
      globalRecVariableId: {
        label: "Global Variable",
        type: "select",
        required: true,
      },
      financialYear: {
        label: "Financial Year",
        type: "select",
        required: true,
      },
      value: {
        label: "Value",
        type: "text",
        required: true,
      },
    },
  },

  constantVariablesListHeaders: {
    _id: "Id",
    name: "Name",
    financialYear: "Financial Year",
    content: "Content",
    type: "Type",
    constantActions: "Actions",
  },

  powerPlantTypeSettings: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      status: {
        label: "Status",
        type: "select",
        options: ["active", "inactive"],
        required: false,
      },
    },
  },

  powerPlantUnitSettings: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      powerPlantId: {
        label: "Power Plant",
        type: "select",
        required: true,
      },
      status: {
        label: "Status",
        type: "select",
      },
    },
  },

  powerPlantTypeListHeaders: {
    _id: "Id",
    name: "Power Plant Type",
    status: "Status",
    powerPlantTypeActions: "Actions",
  },

  powerPlantSettings: {
    fields: {
      name: {
        label: "Name",
        type: "text",
        required: true,
      },
      location: {
        label: "Location",
        type: "button",
        required: true,
      },
      address: {
        label: "Address",
        type: "text",
        required: true,
      },
      city: {
        label: "City",
        type: "text",
        required: true,
      },
      district: {
        label: "District",
        type: "text",
        required: true,
      },
      type: {
        label: "Powerplant Type",
        type: "select",
        options: [
          "solar",
          "wind",
          "hydroelectric",
          "nuclear",
          "coal",
          "naturalGas",
          "biomass",
        ],
        required: true,
      },
      stateId: {
        label: "State",
        type: "select",
        required: true,
      },
      latitude: {
        label: "Latitude",
        type: "text",
        required: true,
      },
      longitude: {
        label: "Longitude",
        type: "text",
        required: true,
      },
    },
  },

  powerPlantListHeaders: {
    _id: "Id",
    name: "Powerplant Name",
    type: "Powerplant Type",
    address: "Address",
    city: "City",
    district: "District",
    state: "State",
    constantPrice: "Constant Price",
    actions: "Actions",
  },

  powerPlantUnitListHeaders: {
    _id: "Id",
    name: "Power Plant Unit Name",
    powerPlantId: "Power Plant",
    actions: "Actions",
  },

  powerPantConstantAdd: {
    fields: {
      financialYear: {
        label: "Financial Year",
        type: "select",
        required: true,
      },
      value: {
        label: "Value",
        type: "text",
        required: true,
      },
    },
  },

  formulaVariablesListHeaders: {
    name: "Variable Name",
    type: "Type",
  },

  recommendationsUnitListListHeaders: {
    _id: "Id",
    name: "Unit Name",
    rtsArea: "Rooftop Area",
    rtsOperatingCharges: "RTS Operating Charges",
    areaParOnekW: "Area for 1kW",
    recUnitActions: "Actions",
  },
};

export default config;
