import React, { useState } from 'react'

import { toast } from 'react-toastify'
import axios from 'axios'
import VariableTypeSelectDropdown from '../regulatoryCharges/VariableTypeSelectDropdown'
import { makeFinancialYear } from '../constantVariable/getFY'

export default function CloneFYpop({label,stateId,onClose,setUpdateCount,discomCode}) {
    
    const [toFinancialYear,setToFinancialYear] =useState("2024-2025")
    const [fromFinancialYear,setFromFinancialYear] =useState("2024-2025")

    const handleToFYchange=(value)=>{
        setToFinancialYear(value)
    }
    const handleFromFYchange=(value)=>{
        setFromFinancialYear(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const fromYear = fromFinancialYear.split("-")[0];
        const toYear = toFinancialYear.split("-")[0];
    
        if (fromYear === toYear) {
            toast.error("The 'from' and 'to' financial years cannot be the same.", {
                position: "top-center",
            });
            return;
        }
    
        try {
            let response
            let formData ={
                id:stateId,
                fromYear: fromYear,
                toYear: toYear,
                discomCode: discomCode
            }
            if(label==="Policy"){
                response = await axios.post(process.env.REACT_APP_API_URL + "/state/bankingPolicy/clone", formData);
            }else {
                response = await axios.post(process.env.REACT_APP_API_URL + "/state/todIncentive/clone", formData);
            }
            
            if (response?.status !== 200) {
                toast.error(`${response?.data?.message}`, {
                    position: "top-center",
                });
            } else {
                toast.success(`${response?.data?.message}`, {
                    position: "top-center",
                });
                setUpdateCount();
                onClose();
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add. Please try again.", {
                position: "top-center",
            });
        }
    }
    


    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl min-h-[30vh] max-h-[50vh] overflow-y-auto">
                    <h2 className="text-xl py-2 w-full text-center font-semibold">Clone {label}</h2>
                    <form>
                        <div className="flex-grow overflow-auto">
                            <div className="max-w-2xl">
                                <div className="bg-white overflow-hidden p-4 gap-4">
                                    <div className="flex flex-col items-left justify-between gap-4 px-4">
                                        <VariableTypeSelectDropdown
                                            label="From Financial Year"
                                            options={makeFinancialYear()}
                                            selectedValue={fromFinancialYear}
                                            onChange={handleFromFYchange}

                                        />
                                        <VariableTypeSelectDropdown
                                            label="To Financial Year"
                                            options={makeFinancialYear()}
                                            selectedValue={toFinancialYear}
                                            onChange={handleToFYchange}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="sticky bottom-0 bg-white px-4 py-2 flex justify-center gap-8">
                            <button
                                onClick={onClose}
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="bg-white border-2 border-solid border-[#067C4E] rounded-lg p-4 text-[#067C4E] hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4"
                            >
                                Clone
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
