import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default function ViewEditDeleteActionButton({
  handleView,
  title,
  handleDelete,
  handleEdit
}) {
  return (
    <>
      <button className="mx-1 px-1" onClick={handleEdit} title={title}>
        <FontAwesomeIcon icon={faEdit} />
      </button>

      {title && (
        <button className="mx-1" onClick={handleView} title={title}>
          <FontAwesomeIcon icon={faEye} />
        </button>
      )}

      <button className="mx-1" onClick={handleDelete}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </>
  );
}
