import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  cleanUpData,
  fetchMetersList,
  fetchUnitsList,
} from "../../services/apis/listings";
import Sidebar from "../../components/sidebar/Sidebar";
import PageTitleCard from "../../components/pageTitleCard/PageTitleCard";
import Table from "../../components/table/Table";
import config from "../../config/config";
import SelectDropdown from "../../components/selectDropdown/SelectDropdown";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import RecordsPerPageDropdown from "../../components/recordsPerPageDropdown/RecordsPerPageDropdown";
import DeleteConfirmation from "../../components/deleteConfirmation/DeleteConfirmation";
import ResetButton from "../../components/resetButton/ResetButton";

export default function MetersList() {
  const location = useLocation();

  const navigate = useNavigate();

  const { metersListTableHeadings } = config;

  const [unitList, setUnitList] = useState([]);
  const [unit, setUnit] = useState([]);
  const [metersListData, setMetersListData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "_id",
    direction: "asc",
  });
  const [count, setCount] = useState(1);
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem("recordsPerPage"), 10);
    return storedLimit || 50;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    unitId: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteItemName, setDeleteItemName] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const unitId = queryParams.get("unitId");
    if (unitId) {
      setUnit(unitId);
      setFilters((filters) => ({ ...filters, unitId: unitId }));
    } else {
      setUnit("");
      setFilters({ unitId: "" });
    }
  }, [location.search]);

  useEffect(() => {
    const fetchParentList = async () => {
      const response = await fetchUnitsList();
      const data = response?.data?.results;
      setUnitList(data);
      // console.log(data,'data')
    };
    fetchParentList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const payload = {
        ...filters,
        page: currentPage,
        pageSize: limit,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction,
      };
      const data = await fetchMetersList(payload);
      const metersList = data?.data?.results;
      const tableData = metersList?.map((item) => ({
        _id: item?._id,
        meterNo: item?.meterNo,
        consumerNo: item?.consumerNo,
      }));
      setMetersListData(tableData);
      setTotalCount(data?.data?.total);
      // console.log(metersList,'metersList')
      setLoader(false);
    };
    fetchData();
  }, [count, currentPage, sortConfig, filters]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleParentChange = (selectedUnit) => {
    setUnit(selectedUnit);
    if (selectedUnit) {
      setFilters({ ...filters, unitId: selectedUnit });
    }
  };

  const updateCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDelete = (id, name) => {
    setDeleteModalOpen(true);
    setDeleteItemId(id);
    setDeleteItemName(name);
  };

  const confirmDelete = async () => {
    const payload = {
      meterId: deleteItemId,
    };
    const response = await cleanUpData(payload);

    if (response.status == 200) {
      toast.success(`Meter Data deleted successfully`, {
        position: "top-center",
        autoClose: 1500,
      });
      updateCount();
    } else {
      toast.error(`Meter Data could not be deleted`, {
        position: "top-center",
        autoClose: 1500,
      });
    }
    setDeleteModalOpen(false);
  };

  const handleRecordsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      unitId: "",
    });
  };
  const handleEdit = (consumerNo) => {
    navigate(`/ocr-list/${consumerNo}`);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-4">
        <div className="max-w-8xl">
          <PageTitleCard title="Meters List" />
          <div className="">
            <div className="overflow-hidden mt-2 rounded-xl">
              <div className="flex">
                <div className="overflow-hidden ">
                  <div className="p-3 flex flex-row gap-4">
                    <SelectDropdown
                      label="Unit"
                      options={unitList?.map((unit) => {
                        return { label: unit?.name, value: unit?._id };
                      })}
                      selectedValue={unit}
                      onChange={(e) => handleParentChange(e.target.value)}
                    />
                    <RecordsPerPageDropdown
                      onRecordsPerPageChange={handleRecordsPerPageChange}
                    />
                  </div>
                </div>
                <div className="p-3 ml-[550px] reset-button-container">
                  <ResetButton onClick={handleResetFilters} />
                </div>
              </div>
              <div className="bg-white p-2 rounded-xl min-h-[450px]">
                <Table
                  loader={loader}
                  headings={metersListTableHeadings}
                  data={metersListData}
                  handleSort={handleSort}
                  handleDelete={handleDelete}
                  sortConfig={sortConfig}
                  handleEdit={handleEdit}
                />
              </div>
            </div>
            {totalCount > limit && (
              <div className="pagination-controls bg-white rounded-b-xl border-[#abaaaa] mb-2 pb-2 flex flex-row items-center justify-end pt-8 -mt-2">
                <button
                  className={`bg-[#e1e0e0] ${
                    currentPage === 1 ? "bg-[#f0f0f0] text-gray-500" : ""
                  } p-3 rounded-xl`}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="mx-4">
                  {Math.min(currentPage * limit, totalCount)
                    ? Math.min(currentPage * limit, totalCount)
                    : "0"}{" "}
                  off {totalCount ? totalCount : "0"}
                </span>
                <button
                  className={`bg-[#e1e0e0] ${
                    currentPage * limit >= totalCount
                      ? "bg-[#f0f0f0] text-gray-500"
                      : ""
                  } p-3 rounded-xl mr-2`}
                  disabled={currentPage * limit >= totalCount}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
        <DeleteConfirmation
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          itemName={deleteItemName}
        />
      </div>
    </div>
  );
}
