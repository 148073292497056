import React, { useState } from 'react';
import AddCentralCharges from './AddCentralCharges';
import AddStateCharges from './AddStateCharges';
import AddDiscomCharges from './AddDiscomCharges';
import VariableTypeSelectDropdown from '../VariableTypeSelectDropdown';
import { makeFinancialYear } from '../getFY';

export default function AddRegulatoryCharges({ onClose, setUpdateCount }) {
    const types = ["central", "state", "discom"];
    const [selectedType, setSelectedType] = useState('central');
    const [financialYear,setFinancialYear] =useState("2024-2025")

    const handleTypeChange = (value) => {
        setSelectedType(value);
    }

    const handleFilterChange=(value)=>{
        setFinancialYear(value)
    }

    const renderComponentByType = () => {
        switch (selectedType) {
            case 'central':
                return <AddCentralCharges key={`central-${financialYear}`} financialYear={financialYear} onClose={onClose} setUpdateCount={setUpdateCount}/>;
            case 'state':
                return <AddStateCharges key={`state-${financialYear}`} financialYear={financialYear} onClose={onClose} setUpdateCount={setUpdateCount}/>;
            case 'discom':
                return <AddDiscomCharges key={`dicom-${financialYear}`} financialYear={financialYear} onClose={onClose} setUpdateCount={setUpdateCount}/>;
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl min-h-[60vh] max-h-[95vh] overflow-y-auto">
                <h2 className="text-xl py-2 w-full text-center font-semibold">Add Regulatory Charges</h2>
                <form>
                    <div className="flex-grow overflow-auto">
                        <div className="max-w-8xl">
                            <div className="bg-white overflow-hidden p-4 gap-4">
                                <div className="flex flex-col items-left justify-between gap-4 px-4">
                                    <VariableTypeSelectDropdown
                                        label="Financial Year"
                                        options={makeFinancialYear()}
                                        selectedValue={financialYear}
                                        onChange={handleFilterChange}
                                    />
                                    <VariableTypeSelectDropdown
                                        label="Variable Type"
                                        options={types}
                                        selectedValue={selectedType}
                                        onChange={handleTypeChange}
                                    />
                                </div>
                                <div className="">
                                    {renderComponentByType()}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
