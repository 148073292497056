import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import {
  fetchOrganisationById,
  updateOrganisationById,
  startRecommendationProcess,
  getOrgTreeViewList,
} from "../../services/apis/listings";
import { makeFinancialYear } from "../recommendations/regulatoryCharges/getFY";

export default function EditOrganisationList({ isOpen, onClose, itemId }) {
  const [orgData, setOrgData] = useState({});
  const [enableRecommendation, setEnableRecommendation] = useState(false);
  const [recommendationUseCache, setRecommendationUseCache] = useState(false);
  const [generateCacheResponse, setGenerateCacheResponse] = useState("pending");
  const [financialYear, setFinancialYear] = useState("2023-2024");
  const [isCacheAvailable, setIsCacheAvailable] = useState(false);
  const [factoryList, setFactoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [meterCount, setMeterCount] = useState([]);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const [response, treeViewData] = await Promise.all([
          fetchOrganisationById(itemId),
          getOrgTreeViewList(itemId),
        ]);
        const data = response?.result;
        const allFactories = treeViewData?.data?.factories?.flatMap(
          (factories) => factories || []
        );
        setFactoryList(allFactories);

        const allUnits = allFactories.flatMap((factory) => factory.units);

        setUnitList(allUnits);
        const allMeters = allFactories.flatMap((factory) =>
          factory.units.flatMap((unit) => unit.meters)
        );

        const allMeterIds = allMeters.map((meter) => meter._id);
        const allMetersWRTState = allUnits.map((e) => {
          return {
            stateCode: e.stateCode,
            meters: e.meters,
          };
        });

        let meterIds = [];

        allMetersWRTState.forEach((state) => {
          state.meters.forEach((meter) => {
            meterIds.push(meter._id);
          });
        });

        setMeterCount(allMetersWRTState);

        setOrgData(data);
        setEnableRecommendation(data?.settings?.recommendation || false);
        setRecommendationUseCache(
          data?.settings?.recommendationUseCacheResponse || false
        );
        setGenerateCacheResponse(
          data?.settings?.generateCacheResponse || "pending"
        );

        checkCacheAvailability(data?.settings?.financialYear);
      } catch (error) {
        console.error("Failed to fetch organisation data:", error);
      }
    };

    if (itemId && isOpen) fetchOrgData();
  }, [itemId, isOpen]);

  useEffect(() => {
    checkCacheAvailability(orgData?.settings?.financialYear);
  }, [financialYear]);

  const checkCacheAvailability = (financialYearSettings) => {
    const startYear = parseInt(financialYear.split("-")[0]);
    setIsCacheAvailable(financialYearSettings?.[startYear] === "true");
  };

  const handleFieldChange = (setter) => (e) => setter(e.target.checked);

  const handleCacheResponseChange = (e) => {
    setGenerateCacheResponse(e.target.checked ? "pending" : "completed");
  };

  const handleCancel = () => {
    onClose();
  };

  const handleFilterChange = (selectedYear) => {
    setFinancialYear(selectedYear);
    checkCacheAvailability(orgData?.settings?.financialYear);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      recommendation: enableRecommendation,
      recommendationUseCacheResponse: recommendationUseCache,
    };

    try {
      const response = await updateOrganisationById(itemId, data);

      if (generateCacheResponse === "pending") {
        const { startDate, endDate } = getFinancialYearDates(financialYear);
        await startRecommendationProcess({
          organisationId: itemId,
          startDate,
          endDate,
        });
      }

      if (response?.data?.error?.code === 200) {
        onClose();
        toast.success(response.data.error.message, { position: "top-center" });
      } else {
        toast.error("Failed to update organisation. Please try again.", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getFinancialYearDates = (year) => {
    const [startYear, endYear] = year.split("-").map(Number);
    return { startDate: `04/${startYear}`, endDate: `03/${endYear}` };
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-lg shadow-lg p-8 w-1/3 text-center">
        <button
          className="absolute -top-2 -right-2 text-gray-700 hover:text-red-500"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faCircleXmark} className="w-8 h-8 " />
        </button>

        {/* Title */}
        <h2 className="text-2xl font-semibold mb-4">
          Edit Organisation Settings
        </h2>
        <div className="flex-grow overflow-auto">
          <div className="p-2 gap-2 font-semibold">
            <label className="flex gap-4">
              <span>Organisation Name :</span>
              {orgData.name}
            </label>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex-grow overflow-auto">
            <div className="p-2">
              <label className="flex items-center justify-between">
                <span className="text-left">Enable recommendation</span>
                <input
                  type="checkbox"
                  className="sr-only peer"
                  id="enableRecommendation"
                  checked={enableRecommendation}
                  onChange={handleFieldChange(setEnableRecommendation)}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            <div className="p-2 gap-2">
              <label className="flex items-center justify-between">
                <span className="text-left">Use cache response</span>
                <input
                  type="checkbox"
                  className="sr-only peer"
                  id="recommendationUseCache"
                  value={recommendationUseCache}
                  checked={recommendationUseCache}
                  onChange={handleFieldChange(setRecommendationUseCache)}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              {meterCount.length > 10 && (
                <div className="mt-4 text-sm text-gray-600 flex text-justify">
                  <span>
                    {" "}
                    <span className="font-bold">
                      MeterCount :{meterCount.length}
                    </span>{" "}
                    It is recommended to use the cache response when there are
                    more than 20 meters.
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            <div className="p-2 gap-2">
              <label className="flex items-center justify-between">
                <span className="flex items-center gap-2">
                  FY :
                  <FilterDropdown
                    label="FY"
                    options={makeFinancialYear()}
                    selectedValue={financialYear}
                    onChange={handleFilterChange}
                  />
                </span>
                <span
                  className="font-semibold"
                  key={isCacheAvailable ? "true" : "false"}
                >
                  {isCacheAvailable ? (
                    <span className="text-[#067C4E]">Cache exists</span>
                  ) : (
                    <span className="text-red-500">No cache exists</span>
                  )}
                </span>
              </label>
            </div>
          </div>

          <div className="flex-grow overflow-auto">
            <div className="p-2 gap-2">
              <label className="flex items-center justify-between">
                <span className="text-left">Start recommendation process</span>
                <input
                  type="checkbox"
                  className="sr-only peer"
                  id="recommendationUseCache"
                  value={
                    generateCacheResponse === "processing" ||
                    generateCacheResponse === "pending"
                  }
                  checked={
                    generateCacheResponse === "processing" ||
                    generateCacheResponse === "pending"
                  }
                  disabled={generateCacheResponse === "processing"}
                  onChange={handleCacheResponseChange}
                />

                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              {/* Conditional message display */}
              {generateCacheResponse === "processing" ? (
                <span className="text-sm text-gray-600 mt-2">
                  Please wait processing in progress for for Fy {financialYear}
                  ...
                </span>
              ) : (
                <span className="text-sm text-gray-600 mt-2">
                  Enable the button to start recommendation process for Fy{" "}
                  {financialYear}
                </span>
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={handleCancel}
              className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-2 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
            >
              Cancel
            </button>
            <button
              type="submit"
              className=" bg-white border-2 border-solid border-[#067C4E] rounded-lg p-2 text-[#067C4E] mt-10 hover:bg-[#067C4E] hover:text-white font-bold py-2 px-4 mr-4"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
